// @ts-strict-ignore

/* istanbul ignore next */

/* (we don't test this, we test the `Account` component directly) */
import React from "react"

import { Account } from "components/account"
import Container from "components/container"
import { RideLayout } from "components/layout-custom"

import { useGlobalState } from "utils/state-utils"

const Ride = () => {
  const { profile, balance, loggedInPersonUid } = useGlobalState()
  return (
    <RideLayout title="My Account">
      <Container>
        <Account
          profile={profile}
          balance={balance}
          loggedInPersonUid={loggedInPersonUid}
        />
      </Container>
    </RideLayout>
  )
}

export default Ride
